import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const TheLoft = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/the-holy-guacamole/gua-1.jpg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/the-holy-guacamole/gua-2.jpg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/the-holy-guacamole/gua-3.jpg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "The Holy Guacamole Central Gurney",
        "The Holy Guacamole Central Gurney",
        "The Holy Guacamole Central Gurney",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | The Holy Guacamole" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-20 mr-5">
                            <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">The Holy Guacamole</h2>
                        <p className="font-light text-gray-600">11am – 11pm daily</p>
                        <p className="font-light text-gray-600">For enquiries: 012-630 7360</p>
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">        
                            Holy Guacamole, is the most celebrated Mexican restaurant in Penang! As soon as you step inside, you’ll be greeted with a warm welcome and a lively atmosphere that features colourful calavera and a burst of fantastic flavours. Put all of this together and you've got the vibrant and fiesta feeling that Holy Guacamole is famous for. From tacos to burritos, serving up a variety of Mexican delights that are sure to tantalize your palate. Not to forget the range of drinks including margaritas and cocktails, which are not to be missed! Whether you're looking for a casual night out with friends or a fun date spot, Holy Guacamole is the perfect place to kick back, relax, and enjoy some delicious food and drinks. So come on down and experience the vibrant and festive feeling that the restaurant is famous for!
                        </p>
                        <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-1-holy.png`} {...imageProps}/>
                        </div>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default TheLoft
